"use client";

import { useEffect, useState, useCallback } from "react";
import { setCookie, deleteCookie, hasCookie } from 'cookies-next';

const consentCookie = "consent_given";
const consentMaxAge = 1000 * 60 * 60 * 24 * 14;  // 14 Days

export default function ConsentBanner() {
    const [consent, setConsent] = useState(true);
    useEffect(() => {
        setConsent(hasCookie(consentCookie));
    }, []);

    const accept = () => {
        setConsent(true);
        setCookie(consentCookie, 'true', { maxAge: consentMaxAge });
        // @ts-ignore
        gtag('consent', 'update', { "ad_storage": 'granted', "analytics_storage": 'granted' });

        console.log("Consent given");
    };

    const reject = () => {
        setConsent(true);
        deleteCookie(consentCookie);
        // @ts-ignore
        gtag('consent', 'update', { "ad_storage": 'denied', "analytics_storage": 'denied' });

        console.log("Consent denied");
    };

    if(consent) {
        return null;
    }

    return (
        <div id="consent-banner" className="justify-between w-[100%] bottom-0 sticky p-3 pb-14 bg-gray-700 sm:pb-40 md:pb-16 screen">
            <p className="mb-2 float-left">
                This site requires cookies to function. Please either accept or reject them.<br />
                Please note: There are some cookies that are required for this webpage to work. Pressing reject will not remove these.
            </p>
            <div className="float-right [&>button]:w-16 [&>button]:text-center [&>button]:border-gray-900 [&>button]:rounded-md [&>button]:mr-2">
                <button onClick={() => accept()} className="bg-green-500 hover:transition-colors hover:text-gray-300 float-right">Accept</button>
                <button onClick={() => reject()} className="bg-red-500 hover:transition-colors hover:text-gray-300 float-right">Reject</button>
            </div>
        </div>
    )
}
