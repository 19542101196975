"use client";

import NavDesktop from "./NavDesktop";
import NavMobile from "./NavMobile";

const sizeMQ = {
    'lg': '(min-width: 1024px)',
    // => @media (min-width: 1024px) { ... }

    'xl': '(min-width: 1280px)',
    // => @media (min-width: 1280px) { ... }

    '2xl': '(min-width: 1536px)',
    // => @media (min-width: 1536px) { ... }

    'md': '(max-width: 767px)',
    // => @media (max-width: 767px) { ... }

    'sm': '(max-width: 639px)',
    // => @media (max-width: 639px) { ... }
}

export default function NavBar() {
    return (
        <>
            <NavDesktop />
            {/* <NavMobile /> */}
        </>
    )
}