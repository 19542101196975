"use client";
import { CldImage } from 'next-cloudinary'
import Link from 'next/link';
import { StyledLink } from './Link';
import NavBar from './navigation/Nav';

export default function Header() {
    return (
        <div id="header" className='flex justify-between pb-6 m-5 screen'>
            <Link 
                href='/#'
                className='float-left'    
            >
                <CldImage
                    src="logo-banner"
                    width='200'
                    height='100'
                    alt='jkdev'
                    loading='eager'
                    fetchPriority='high'
                    priority
                />
            </Link>

            <nav id="nav" className='float-right flex justify-between gap-3 m-3 p-2'>
                <NavBar />
            </nav>

        </div>
    )
}

// 