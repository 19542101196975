'use client';
import Link from "next/link";


export function StyledLink({ href, text, className = "", children, onClick = ()=>{} }: { href: string, text?: string, className?: string, children?: any, onClick?: () => void}) {
    let LinkText = text !== undefined ? text : children;
    
    return (
        <Link onClick={onClick} href={href}>
            <span className={"hover:transition-colors duration-50 hover:text-blue-300 " + className}>{LinkText}</span>
        </Link>
    )
}

export function StyledLinkExternal({ href, text, className = "", children, target = "__blank", alt = "" }: { href: string, text?: string, className?: string, children?: any, target?: string, alt?: string}) {
    let LinkText = text !== undefined ? text : children
    
    return (
        <a href={href} target={target} aria-label={alt}>
            <span className={"underline decoration-blue-500 hover:decoration-purple-700 transition-colors duration-75 " + className}>{LinkText}</span>
        </a>
    )
}