import { StyledLink } from "../Link";
import { useState, useEffect, useMemo } from "react";

export default function NavDesktop() {
    let [outerpage, setPage] = useState(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    let pages = [{ "idx": 1, "name": "Blog", "href": "/post" },]

    useEffect(() => {
        let path = window.location.pathname;
        let poss_pages = pages.filter(page => page.href === path);
        if (poss_pages.length > 0) setPage(poss_pages[0].idx);
        else setPage(0);
    }, [pages])

    return (
        <div className="md:hidden flex flex-row [&>a]:pl-8">
            { pages.map(page => <StyledLink key={page.idx} href={page.href} text={page.name} className={`hover:transition-colors duration-50 hover:text-blue-300 ${outerpage === page.idx ? "font-black" : ""}`} onClick={() => setPage(page.idx)}/>) }
        </div>
    )
}